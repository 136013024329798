// @media screen and (max-width: 640px) {
//     .filter-drops-container {
//         flex-wrap: nowrap !important;
//         overflow-x: auto;
//     }
// }

.apply_youth_modal {
    padding-left: 0 !important;
}

.input-sub-text {
    font-size: 14px;
}