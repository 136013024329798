.mentors_profile {
  margin-top: 15px;
}
.mentors_profile .card_name {
  background-color: #174A38;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.team_x_sect div {
  margin-right: 1%;
  // min-width: 350px;
  padding-bottom: 30px;
}

.mentors_logo_text {
  font-size: 16px;
  font-weight: 600;
}

.mentors_head_youths_mob {
  display: none;
}

.heading {
  color: #262F3F;
}

.educator-container {
  width: 270px;
}

.educator-container-image-container {
  width: 100%;
  height: 271px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

.educator-container-image {
  width: 100%;
  height: 271px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.educator-container-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 67px !important;
  gap: 10px;
  width: 270px;
  height: 47px;
  background: #174A38;
  white-space: nowrap;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 768px) {
  .mentors_logo_text {
    font-size: 16px;
  }

  .mentors_head_youths {
    display: none;
  }

  .mentors_head_youths_mob {
    display: flex;
    margin-top: 10px;
  }

  .team_x_sect div {
    min-width: 260px;
  }  

  .educator-container {
    width: 148px
  }

  .educator-container-image-container {
    height: 148px
  }

  .educator-container-image {
    height: 148px;
  }
}