$dropColor: #E8EDF5;

.resource_overview_tab .main_content .sect_header{
  text-transform: uppercase;
  font-weight: 600;
}

.main_content .sect_header_right {
  color: #226E54;
}

.resource_overview_tab .main_content .cards_grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  gap: 2%;
  margin-bottom: 30px;
}

.resource_overview_tab .cards_grid .card {
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.resource_overview_tab .opp_drops button{
  background-color: $dropColor!important;
  border: none;
}

.resource_overview_tab .opp_drops.three_dots button::after {
  display: none;
}

.resource_overview_tab .opp_drops button:hover{
  background-color: $dropColor!important;
}

.resource_overview_tab .three_dots_v_wrapper {
  padding: 5px;
  background: #DDDFE2;
  border-radius: 3px;
  height: 40px;
}

.review_tab {
  display: flex;
  justify-content: space-between;
}

.review_tab > div:first-child{
  width: 30%;
  border: 1px solid #F4F5F7;
  border-radius: 15px;
}

.review_tab .left_sect {
  width: 67%;
}

.progress_bar_wrapper {
  width: 80%;
  margin: 5px 10px 0 0;
}

.progress_bar_wrapper .progress-bar {
  background-color: #FFC768;
}

.review_active {
  background: #FFFFFF;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.review_active .star_fill_color {
  color: #806334;
}

.review_active .progress-bar {
  background-color: #806334!important;
}

.review_tab .right_card {
  border: 1px solid #F4F5F7;
  border-radius: 15px;
  padding: 10px;
}

.opportunities_tab .public_opportunity {
  background-color: #fff;
  padding: 0;
}

.resource_overview_tab .cards.mobile_dev {
  display: none;
}

.opportunities_tab .section_main {
  padding: 20px;
}

.resource-welcome-message {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #2E394C;
}

.resource-detail-description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2E394C;
}

.link-show-all {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #226E54;
}

.resource_overview_tab .heading {
  color: #262F3F;
}

.review-date {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #515A6A;
}

.review-description {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #515A6A;
}

@media (max-width: 768px) {
  .resource_overview_tab .main_content .cards_grid {
    display: none;
  }

  .resource_overview_tab .cards.mobile_dev {
    display: flex;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .resource_overview_tab .cards.mobile_dev .card{
    min-width: 95%;
    margin-right: 3%;
    padding: 10px;
  }

  .review_tab {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto;
  }

  .review_tab > div:first-child{
    width: 100%;
  }
  
  .review_tab .left_sect {
    width: 100%;
  }

  .review_tab .left_sect > div:first-child {
    margin-top: 20px;
  }

  .opportunities_tab .section_main {
    padding: 10px;
  }
}