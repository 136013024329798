
:root {
  --color-warning: #FFE3B3;
  --color-warning-light: #FFF4E1;
  --color-warning-medium: #FFC768;
  --color-warning-deep: #554223;
  --color-danger: #D41811;
  --color-tertiary: #FFDA9A;
  --color-neutral: #515A6A;
  --color-success-light: #DAF8EE;
  --color-success: #A1EED4;
  --color-success-deep: #226E54;
  --color-secondary: #2E394C;
  --color-secondary-light: #969CA5;
  --color-primary: #10408C;
}

$bg: #F4F5F7;
$secondaryColor: #2E394C;
$primaryColor: #10408C;
$inputTitleColor: #515A6A;

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, button, span {
  font-family: "Manrope";
  letter-spacing: 1px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.object-fit-cover {
  object-fit: cover;
}

.color_primary {
  color: $primaryColor;
}

.input_title {
  color: $inputTitleColor;
}

.bold {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #515A6A;
}

a, p {
  font-family: "Manrope";
  letter-spacing: 0.5px;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none;
  background-color: $bg;
  color: #2E394C;
}

.btn-primary:hover {
  background-color: #fff!important;
  color: #000!important;
}

.ml-10 {margin-left: 10px;}
.mt-10 {margin-top: 10px;}
.mt-40 {margin-top: 40px;}
.mb-10 {margin-bottom: 10px;}
.mb-20 {margin-bottom: 20px;}
.mv-10 {margin: 10px 0;}
.mh-10 {margin: 0 10px;}
.mg-10 {margin: 10px 10px;}
.mg-20 {margin: 20px 20px;}
.mt-20 {margin-top: 20px;}
.ml-10 {margin-left: 10px;}
.mr-20 {margin-right: 20px;}
.mr-10 {margin-right: 10px;}
.mbd {border-bottom: 1px solid rgba(207, 217, 232, 0.4);}

.pd-10 {padding: 10px 10px!important;}
.ph-20 {padding: 0 20px;}
.pd-20 {padding: 20px 20px!important;}
.pv-10 {padding: 10px 0;}
.pv-20 {padding: 20px 0;}
.ph-10 {padding: 0 10px;}
.pb-10 {padding-bottom: 10px;}
.pb-20 {padding-bottom: 20px;}

.flex-v {
  display: flex;
  flex-direction: column;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-items {
  display: flex;
  align-items: center;
}

.point {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.overflow {
  overflow: auto;
}

.dropdown-item:active,.dropdown-item:focus {
  background-color: $secondaryColor;
}

.cdata_range svg {
  margin-top: 5px;
}

.btn {
  color: #2E394C;
}

input[type="checkbox"]:focus{
  box-shadow: 0 0 0 0 $secondaryColor;
}

input[type="checkbox"]:checked {
  background-color: $primaryColor;
  box-shadow: 0 0 0 0 $primaryColor;
}

input[type="text"]:focus {
  background-color: rgba(244, 245, 247, 0.64)!important;
}

.btn-primary:focus{
  box-shadow: 0 0 0 0!important;
} 

.btn-primary:active{
  box-shadow: 0 0 0 0;
} 

.border-dashed {
  border-style: dashed;
}

hr {
  outline: 0.5px solid #F4F5F7;
}

.fs-28 {
  font-size: 28px;
}

.card {
  border: 1px solid #F4F5F7
}

hr {
  color: #F4F5F7;
  opacity: 1;
}

.badge-radio span {
  border: 1px solid #DDDFE2;
  border-radius: 32px;
  padding: 11px 12px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #2E394C;
  white-space: nowrap;
}

.badge-radio:hover.clickable span,
.badge-radio.clickable input:checked ~ span {
  background: var(--color-warning);
  color: var(--color-warning-deep);
  border-color: var(--color-warning-medium);
}

.badge-radio.badge-radio-success.clickable:hover span,
.badge-radio.badge-radio-success.clickable input:checked ~ span {
  background: var(--color-success-light);
  border: 1px solid var(--color-success);
  color: var(--color-success-deep);
}

.custom-form-label {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: $inputTitleColor;
}

.multiselect-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  background: #FFE3B3;
  border: 1px solid #FFC768;
  border-radius: 8px;
}

.multiselect-btn span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #554223;
  white-space: nowrap;
}

.start_typing_input {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid #DDDFE2;
  border-radius: 8px;
}

.start_typing_input input {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #969CA5;
}

.custom-form-input {
  padding: 8px;
  gap: 8px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #DDDFE2;
  border-radius: 8px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #969CA5;
}

.custom-flex-wrap {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--color-secondary);
}

.subheading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #969CA5;
}

.btn-outline-primary {
  --bs-btn-color: var(--color-primary);
  --bs-btn-border-color: var(--color-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-primary);
  --bs-btn-hover-border-color: var(--color-primary);
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-primary);
  --bs-btn-active-border-color: var(--color-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-primary);
  --bs-gradient: none;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: var(--color-warning-light);
  --bs-btn-border-color: var(--color-warning-medium);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: var(--color-warning-light);
  --bs-btn-hover-border-color: var(--color-warning-medium);
  --bs-btn-focus-shadow-rgb: 217,164,6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: var(--color-warning-medium);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: var(--color-warning-medium);
  --bs-btn-disabled-border-color: var(--color-warning-medium);
}

.alert {
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1056;
}