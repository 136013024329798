
$standardBg: #10408C;
$secondaryBg: #fff;
$yellowColor: #FFE3B3;
$grayColor: #F4F5F7;


.btn_with_icon_wrapper {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  max-width: 180px;
}

.btn_with_icon_wrapper.primary {
  background-color: $standardBg;
  color: #fff;
  padding: 10px;
}

.btn_with_icon_wrapper.secondary {
  background-color: $secondaryBg;
  color: $standardBg;
  border: 1px solid $standardBg;
  padding: 5px;
}

.btn_with_icon_wrapper.no-bd {
  border: none;
}

.btn_with_icon_wrapper.align_left {
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.btn_with_icon_wrapper span:first-child {
  margin-right: 10px;
}

.btn_with_icon_wrapper.yellow_btn {
  background-color: $yellowColor;
  border-radius: 10px;
  color: #2E394C;
  border: 1px solid #FFC768;
  padding: 5px;
}

.btn_with_icon_wrapper.light_yellow_btn {
  background-color: #FFECCD;
  border-radius: 25px;
  color: #554223;
  border: 1px solid #FFC768;
  padding: 5px;
  width: 500px;
}

.btn_with_icon_wrapper.gray {
  background-color: $grayColor;
  border-radius: 10px;
  color: #2E394C;
  font-size: 14px;
  border: 1px solid #DDDFE2;
  padding: 10px;
}

.btn_with_icon_wrapper.yellow_btn.long {
  padding: 10px;
  border-radius: 30px;
}

.btn_with_icon_wrapper.long {
  max-width: 100%;
}

.btn-copy:hover svg path {
  fill: white;
}
