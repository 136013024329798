$primaryColor: #10408C;
$secondaryColor: #fff;
$yellowColor: #FFE3B3;
$greenColor: #DAF8EE;

.btn_wrapper {
  padding: 10px;
  border: none;
  max-width: 200px;
  display: flex;
  justify-content: center;
}

.btn_wrapper.primary {
  background-color: $primaryColor;
  color: #fff;
  border-radius: 8px;
}

.btn_wrapper.primary input[type='button'] {
  background-color: inherit;
  color: #fff;
  border: none;
}

.btn_wrapper.secondary {
  background-color: $secondaryColor;
  border-radius: 20px;
  border: 1.5px solid #DDDFE2;
}

.btn_wrapper.secondary input[type='button'] {
  background-color: inherit;
  color: #2E394C;
  border: none;
}

.btn_wrapper.yellow_btn {
  background-color: $yellowColor;
  border-radius: 20px;
  border: 1.5px solid #FFC768;
}

.btn_wrapper.yellow_btn input[type='button'] {
  background-color: inherit;
  color: #554223;
  border: none;
}

.btn_wrapper.green_btn {
  background-color: $greenColor;
  border-radius: 20px;
  border: 1.5px solid #A1EED4;
}

.btn_wrapper.green_btn input[type='button'] {
  background-color: inherit;
  color: #226E54;
  border: none;
}

.btn_wrapper.education {
  background-color: #CFD9E8;
  border-radius: 20px;
  border: 1.5px solid #AFBFD9;
}

.btn_wrapper.education input[type='button'] {
  background-color: inherit;
  color: #0D3575;
  border: none;
}

.btn_wrapper.employment {
  background-color: $yellowColor;
  border-radius: 20px;
  border: 1.5px solid #FFC768;
}

.btn_wrapper.employment input[type='button'] {
  background-color: inherit;
  color: #554223;
  border: none;
}

.btn_wrapper.small {
  width: 100px;
  height: 30px;
  border-radius: 20px;
  font-size: 12px;
  padding: 1px 5px;
}

.btn_wrapper.green_btn.small {
  width: 100px;
  height: 30px;
  border-radius: 20px;
  padding: 2px 15px;
}

.btn_wrapper.large {
  width: 110px;
  height: 50px;
}

.btn_wrapper.long {
  max-width: 100%;
}

.btn_wrapper.disabled {
  opacity: 0.6;
}