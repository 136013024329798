$deepGreenColor: #226E54;
$lightGreenColor: rgba(218, 248, 238, 0.4);
$secondaryColor: #515A6A;

.resource_profile {
  background-color: #FAFCFE;
  padding: 2% 5%;
  min-height: 92.5%;
  margin-top: 15px;
}

.resource_profile_main {
  background-color: #fff;
  border-radius: 20px;
  height: 100%;
  padding: 20px 20px;
}

.resource_profile_main .btn_with_icon_wrapper.green_fg {
  max-width: 400px;
  border-radius: 15px;
  border: 1px solid #DDDFE2;
  color: #226E54;
}

.resource_profile .nav-tabs button[aria-selected=true] {
  color: $deepGreenColor!important;
  border-bottom: 2px solid $deepGreenColor;
  background-color: $lightGreenColor!important;
  border-radius: 4px 4px 0px 0px;
  font-weight: 700;
}

.resource_profile .nav-tabs .nav-link {
  border: none;
}

.resource_profile .nav-tabs .nav-link.active {
  border-bottom: 2px solid $deepGreenColor;
}

.resource_profile .nav-tabs button {
  color: $secondaryColor;
}

.resource_profile .right_web_icon {
  min-width: 110px;
}

.resource_profile .resources_review_div_mob {
  display: none;
}

.btn-website {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 104px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #DDDFE2;
  border-radius: 32px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #226E54;
  white-space: nowrap;
}


@media (max-width: 768px) {
  .resource_profile {
    background-color: #fff;
    padding: 0 2%;
  }

  .resource_profile .resources_review_div {
    display: none;
  }

  .resource_profile .resources_review_div_mob {
    display: flex;
  }
}

