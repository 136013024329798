
.parental_verification_page {
  padding: 3%;
  max-width: 600px;
  margin-top: 15px;
  .top_section {
    h4 {
      font-weight: 500;
    }
  }
  .input_title_sub {
    font-size: 16px;
  }

  input[type=checkbox]:checked {
    background-color: #2D9371
  }
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}