.youth-login-wrapper {
  height: 100vh;
  .qr-code-sect {
    background: linear-gradient(0deg, rgba(62,62,69,0.4), rgba(62,62,69,0.4)), url('../../assets/images/youth_login_desktop_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 50%;
    .qr-code {
      height: auto;
      margin: 0 auto;
      max-width: 20%;
      width: 100%;
      background-color: #fff;
      padding: 15px;
      border-radius: 20px;
    }
  }
  .content-details {
    background-color: #000;
    color: #fff;
    text-align: center;
    flex-direction: column;
    height: 50%;
    .sub-header-text {
      width: 500px;
      font-size: 14px;
      text-align: left;
      line-height: 26px;
    }
    .installation-guide {
      margin-top: 30px;
    }
    .item_no {
      height: 30px;
      width: 30px;
      color: #515A6A;
      background-color: #AFBFD9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .push_item {
      margin-left: 8px;
    }
  }
  .globe_link_wrapper {
    background-color: #FFECCD;
    display: flex;
    justify-content: center;
    color: #554223;
    border-radius: 40px;
  }
}